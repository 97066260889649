<template>
  <div class="landing">
    <v-container>
      <v-breadcrumbs :items="items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
              :to="item.to"
              :disabled="item.disabled"
          >
            {{ item.text.toUpperCase() }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <h1>{{ objPage.name }}</h1>
      <div class="landing__wrapper text-justify" v-html="objPage.content"></div>
      <button-back-to-top />
    </v-container>
  </div>
</template>

<script>
import * as names from "../store/names";
import { mapActions, mapGetters } from "vuex";
import ButtonBackToTop from "components/partials/ButtonBackToTop";

export default {
  metaInfo: {
    title: "Помощь",
    titleTemplate: "%s ← Medveddoc",
    meta: [
      {
        name: "description",
        content:
          "Подбор врача по цене, клинике. График работы врачей. Подбор медицинских услуг по РФ",
      },
      { charset: "utf-8" },
      { property: "og:title", content: "Помощь" },
      { property: "og:site_name", content: "medveddoc.com" },
      { property: "og:type", content: "website" },
      { property: "og:url", content: "https://medveddoc.com" },
      {
        property: "og:description",
        content:
          "Подбор врача по цене, клинике. График работы врачей. Подбор медицинских услуг по РФ",
      },
    ],
  },
  name: "TheHelpPage",
  components: {
    ButtonBackToTop,
  },
  created() {
    this.getPage({ slug: "help" });
  },
  data: () => ({
    items: [
      {
        text: 'главная',
        disabled: false,
        to: '/',
      },
      {
        text: 'Помощь',
        disabled: true,
        to: '/help',
      }],
  }),
  computed: {
    ...mapGetters({
      objPage: names.OBJ_PAGE,
    }),
  },
  methods: {
    ...mapActions({
      getPage: names.ACTION_GET_PAGE,
    }),
  },
};
</script>

<style lang="scss" scoped>
.v-breadcrumbs {
  padding: 18px 0px ;
}
</style>
